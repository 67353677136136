
  import { reactive, ref, onMounted, computed } from 'vue'
  import { useStore } from "vuex";
  import { Actions } from "@/store/enums/InvoiceLotsEnums.ts";
  import { Actions as LotActions } from "@/store/enums/LotEnums.ts";
  import {Actions as SupplierActions} from '@/store/enums/SupplierEnums.ts';
  import { useRouter } from "vue-router";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  export default {
    name : 'InvoiceLotsAdd',
    setup(){

      const store = useStore();
      const router = useRouter();

      onMounted(() => {

        store.dispatch(LotActions.GET_COMPOSITE_LOTS_ON_STOCK,{});
        store.dispatch(SupplierActions.ALL_SUPPLIERS);

        setCurrentPageBreadcrumbs("Lots",[
          {title : "Invoiced Lots List",to : "/lots/invoice-composites"},
          {title : "Invoice Composite",to : "/lots/invoice-composites/add"}
        ]);
      });



      const submitButton = ref<HTMLElement | null>(null);

      const success = ref(false)

      const errors = reactive({
        value : {}
      });

      const formData = reactive({
        lot_id : null,
        supplier_id : null,
        quantity : 1
      })

      const submitForm = () => {

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.ADD_INVOICE_LOT,formData).then(() => {

          success.value = true;
          errors.value = [];
          submitButton.value?.removeAttribute("data-kt-indicator");
          window.scrollTo(0,0);
          setTimeout(function(){
            router.push('/lots/invoice-composites')
          },1000)

        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      const compositeLotsOnStock = computed(() => {
        const lots : string[] = [];

        store.getters.mycompositeLotsOnStock.forEach((item) => {

          if(Number(item.inStock) > 0){

            lots.push(item)

          }

        });
        return lots;
      })

      const customerSuppliers = computed(() => {
        const customers : string[] = [];

        store.getters.myAllSuppliers.forEach((item) => {

          if(Number(item.isCustomer) == 1){

            customers.push(item)

          }

        });
        return customers;
      })

      return {
        submitButton,
        formData,
        submitForm,
        errors,
        success,
        compositeLotsOnStock,
        customerSuppliers
      }
    }
  }
