<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Invoice Lots</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->

      <!--begin::Card body-->
      <div class="card-body p-9">

        <div class="row mb-3" v-show="success">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully invoiced composites!
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">LOT</label>
            <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.lot_id}" v-model="formData.lot_id">
              <option selected disabled value="null">Select Lot</option>
              <option :value="item.lot_id" v-for="(item,key) in compositeLotsOnStock" :key="key">{{item.code}} / {{item.lotNumber}}</option>
            </select>
            <span class="text-danger" v-if="errors.value && errors.value.lot_id">{{errors.value && errors.value.lot_id[0]}}</span>
          </div>
          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Customer</label>
            <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.supplier_id}" v-model="formData.supplier_id">
              <option value="null" selected disabled>Select Customer</option>
              <option :value="item.id" v-for="(item,key) in customerSuppliers" :key="key">{{item.name}}</option>
            </select>
            <span class="text-danger" v-if="errors.value && errors.value.supplier_id">{{errors.value && errors.value.supplier_id[0]}}</span>
          </div>
          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <label class="col-form-label required fw-bold fs-6">Quantity</label>
            <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.quantity}" v-model="formData.quantity">
            <span class="text-danger" v-if="errors.value && errors.value.quantity">{{errors.value && errors.value.quantity[0]}}</span>
          </div>
        </div>

      </div>
      <!--end::Card body-->
      <div class="card-footer cursor-pointer">
        <!--begin::Card title-->
        <div class="text-end m-0">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder me-3 my-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Card title-->
      </div>
    </div>

  </div>
</template>
<script lang="ts">
  import { reactive, ref, onMounted, computed } from 'vue'
  import { useStore } from "vuex";
  import { Actions } from "@/store/enums/InvoiceLotsEnums.ts";
  import { Actions as LotActions } from "@/store/enums/LotEnums.ts";
  import {Actions as SupplierActions} from '@/store/enums/SupplierEnums.ts';
  import { useRouter } from "vue-router";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  export default {
    name : 'InvoiceLotsAdd',
    setup(){

      const store = useStore();
      const router = useRouter();

      onMounted(() => {

        store.dispatch(LotActions.GET_COMPOSITE_LOTS_ON_STOCK,{});
        store.dispatch(SupplierActions.ALL_SUPPLIERS);

        setCurrentPageBreadcrumbs("Lots",[
          {title : "Invoiced Lots List",to : "/lots/invoice-composites"},
          {title : "Invoice Composite",to : "/lots/invoice-composites/add"}
        ]);
      });



      const submitButton = ref<HTMLElement | null>(null);

      const success = ref(false)

      const errors = reactive({
        value : {}
      });

      const formData = reactive({
        lot_id : null,
        supplier_id : null,
        quantity : 1
      })

      const submitForm = () => {

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.ADD_INVOICE_LOT,formData).then(() => {

          success.value = true;
          errors.value = [];
          submitButton.value?.removeAttribute("data-kt-indicator");
          window.scrollTo(0,0);
          setTimeout(function(){
            router.push('/lots/invoice-composites')
          },1000)

        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      const compositeLotsOnStock = computed(() => {
        const lots : string[] = [];

        store.getters.mycompositeLotsOnStock.forEach((item) => {

          if(Number(item.inStock) > 0){

            lots.push(item)

          }

        });
        return lots;
      })

      const customerSuppliers = computed(() => {
        const customers : string[] = [];

        store.getters.myAllSuppliers.forEach((item) => {

          if(Number(item.isCustomer) == 1){

            customers.push(item)

          }

        });
        return customers;
      })

      return {
        submitButton,
        formData,
        submitForm,
        errors,
        success,
        compositeLotsOnStock,
        customerSuppliers
      }
    }
  }
</script>
